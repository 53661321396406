<script setup>
import HappinessGuarantee from '~/assets/icons/guarantee.svg'
const { $label } = useNuxtApp()

defineProps({
  isDefault: {
    required: true,
    type: Boolean,
  },
  isGuarantee: {
    type: Boolean,
    required: false,
  },
})

const socials = [
  {
    label: 'instagram',
    iconName: 'social-instagram',
  },
  {
    label: 'facebook',
    iconName: 'social-facebook',
  },
  {
    label: 'facebookGroup',
    iconName: 'facebook-group',
  },
]

const socialPlatforms = socials.map((platform) => ({
  componentName: defineAsyncComponent(
    () => import(`~/assets/icons/${platform.iconName}.svg`),
  ),
  url: $label(`settings.socialPlatforms.${platform.label}`),
}))

const currentYear = new Date().getFullYear()
</script>

<template>
  <footer class="footer pb-40">
    <div class="container">
      <hr class="mb-32" />
      <div
        class="d-flex flex-column flex-lg-row align-items-md-center text-center"
      >
        <div v-if="isDefault" class="mb-24 mb-md-0 mr-lg-24">
          <a
            v-for="platform in socialPlatforms"
            :key="platform.url"
            :href="platform.url"
            target="_blank"
            class="social-links fill-inherit"
            :aria-label="platform"
          >
            <component :is="platform.componentName" :key="platform.label" />
          </a>
        </div>
        <div v-if="isDefault">
          <nav class="d-flex flex-column flex-md-row bc-mt-md-24 bc-mt-lg-0">
            <NuxtLink
              class="links text-small mt-8 mt-md-0"
              to="/boekenpraat"
              aria-label="boekenpraat"
            >
              <span>
                {{ $label('common.footer.blog') }}
              </span>
            </NuxtLink>

            <NuxtLink
              class="links text-small mt-8 mt-md-0"
              to="/privacy"
              aria-label="privacy"
            >
              <span>
                {{ $label('common.footer.privacy') }}
              </span>
            </NuxtLink>

            <NuxtLink
              class="links text-small mt-8 mt-md-0"
              to="/algemene-voorwaarden"
              aria-label="algemene voorwaarden"
            >
              <span>
                {{ $label('common.footer.toc') }}
              </span>
            </NuxtLink>

            <NuxtLink
              class="links text-small mt-8 mt-md-0"
              to="/contact"
              aria-label="contact"
            >
              <span>
                {{ $label('common.footer.contact') }}
              </span>
            </NuxtLink>
          </nav>
        </div>
        <div v-if="isGuarantee">
          <div class="col-12 text-center m-0 p-0">
            <div class="guarantee-footer-icon">
              <HappinessGuarantee />
            </div>
          </div>
        </div>
        <p class="text-small ms-lg-auto mb-0 mt-24 mt-lg-0">
          {{
            $label('common.footer.copyright', {
              year: currentYear,
            })
          }}
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  position: sticky;
  top: 100vh;
  margin-top: $footer-margin-top;
}

.social-links {
  align-items: center;
  background-color: $brown-50;
  border-radius: 50%;
  color: $grey-800;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  margin-right: 16px;
  width: 36px;

  &:last-child {
    margin-right: 0;
  }
}

.links {
  color: $grey-800;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    display: flex;

    &:not(:last-of-type) {
      &::after {
        border-left: 1px solid $grey-300;
        content: '';
        height: 16px;
        margin: 3px 24px 0 23px;
      }
    }
  }
}

.guarantee-footer-icon {
  width: 50px;
  @include media-breakpoint-down(md) {
    margin: 0 auto;
  }
}
</style>
